import React from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Card } from "react-bootstrap";
import ServiceHero from "../components/service-hero";
import moment from "moment";

const PostTemplate = ({ data: { post, posts, site } }) => {
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${site.siteMetadata.siteUrl}`,
					id: `${site.siteMetadata.siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Blog",
				item: {
					url: `${site.siteMetadata.siteUrl}/blog`,
					id: `${site.siteMetadata.siteUrl}/blog`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${site.siteMetadata.siteUrl}/blog/${post.slug}`,
					id: `${site.siteMetadata.siteUrl}/blog/${post.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${post?.seoFields?.title}`}
				description={post?.seoFields?.metaDescription}
				language="en"
				openGraph={{
					title: `${post?.seoFields?.opengraphTitle}`,
					description: `${post?.seoFields?.opengraphDescription}`,
					url: `${site.siteMetadata.siteUrl}/blog/${post.slug}`,
					type: "article",
					images: [
						{
							url: `${post.blogFields.featuredImage.node.localFile.publicURL}`,
							width: `${post.blogFields.featuredImage.node.localFile.childImageSharp.original.width}`,
							height: `${post.blogFields.featuredImage.node.localFile.childImageSharp.original.height}`,
							alt: `${post.blogFields.featuredImage.node.altText}`,
						},
					],
				}}
			/>
			<section>
				<ServiceHero
					title={post.title}
					backgroundImage={
						post.blogFields.featuredImage.node.localFile.childImageSharp
							.gatsbyImageData
					}
					imgAlt="ECF Blog posts page"
				/>
			</section>

			<section>
				<Container>
					<Row className="justify-content-center py-5 py-lg-7">
						<Col className="position-relative" lg={10} xl={8}>
							{parse(post.content)}
							<Row className="mt-4 mt-lg-5">
								<Col className="text-end">
									<div className="d-flex align-items-center justify-content-center justify-content-lg-end">
										{post.categories.nodes.map((category, index) => (
											<div className="bg-grey px-2 me-2 mb-2">
												<Link
													to={`/blog/categories/${category.slug}`}
													key={index}
													className="secondary-link inter-bold"
												>
													{category.name}
												</Link>
											</div>
										))}
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5 pb-lg-7">
				<Container>
					<Row>
						<Col className="text-center    mt-lg-5">
							<h2 className=" fs-1  pb-5 ">Related posts</h2>
						</Col>
					</Row>
					<Row className=" h-100 gy-3 gy-lg-5">
						{posts.nodes.map((post, index) => (
							<Col key={index} md={6} lg={4} className="mb-4">
								<Card style={{ border: "0px" }} className="h-100 ">
									<Card.Img
										as={GatsbyImage}
										variant="top"
										style={{ height: "225px" }}
										image={
											post.blogFields.featuredImage.node.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={post.blogFields.featuredImage.node.altText}
									/>
									<Card.Body className="p-3 bg-grey position-relative pb-5">
										<Card.Title className="text-black pe-5">
											{post.title}
										</Card.Title>
										<Card.Text>{post.blogFields.description}</Card.Text>
										<div className="position-absolute bottom-0 start-0 p-3 ">
											<Link
												to={`/blog/${post.slug}`}
												className="black-link inter-bold"
											>
												Read More
											</Link>
										</div>
										<div className="top-0 end-0  position-absolute translate-middle-y pe-3">
											<div
												style={{
													borderRadius: "100%",
													height: "50px",
													width: "50px",
												}}
												className="bg-white text-center d-flex align-items-center justify-content-center"
											>
												<p
													style={{ fontSize: "85%", lineHeight: "105%" }}
													className="p-0 m-0 "
												>
													<span className="inter-bold pb-0 mb-0">
														{moment(post.dateGmt).format("DD").toUpperCase()}
													</span>
													<br />
													{moment(post.dateGmt).format("MMM").toUpperCase()}
												</p>
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
					<Row>
						<Col className="text-center  mt-4  mt-lg-5">
							<Button
								style={{ borderRadius: "0px" }}
								variant="secondary"
								as={Link}
								to="/blog"
								className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
							>
								View All
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default PostTemplate;

export const pageQuery = graphql`
	query BlogPostById($id: String!) {
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			slug
			content
			categories {
				nodes {
					name
					slug
				}
			}
			title

			blogFields {
				
				description
				metaDescription
				featuredImage {
					node {
						altText
						localFile {
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
			seoFields {
				title
				opengraphTitle
				opengraphDescription
				metaDescription
			  }
		}

		posts: allWpPost(
			sort: { order: DESC, fields: dateGmt }
			limit: 3
			filter: { id: { ne: $id }, title: { ne: "Website Images" } }
		) {
			nodes {
				dateGmt
				title
				excerpt
				slug
				blogFields {
					description
					metaDescription
					featuredImage {
						node {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
